"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _sizes = require("@coastomizer/sizes");
var sizes = {
  xs: {
    horizontalPadding: (0, _sizes.pixular)(_sizes.genericSizeConstants.xs * 3),
    verticalPadding: (0, _sizes.pixular)(_sizes.genericSizeConstants.xs * 2),
    fontSize: (0, _sizes.pixular)(_sizes.pixularFontSizeConstants.xs),
    iconMargin: (0, _sizes.pixular)(_sizes.genericSizeConstants.xs)
  },
  sm: {
    horizontalPadding: (0, _sizes.pixular)(_sizes.genericSizeConstants.xs * 3),
    verticalPadding: (0, _sizes.pixular)(_sizes.genericSizeConstants.xs * 2),
    fontSize: (0, _sizes.pixular)(_sizes.pixularFontSizeConstants.sm),
    iconMargin: (0, _sizes.pixular)(_sizes.genericSizeConstants.xs)
  },
  md: {
    horizontalPadding: (0, _sizes.pixular)(_sizes.genericSizeConstants.xs * 5),
    verticalPadding: (0, _sizes.pixular)(_sizes.genericSizeConstants.xs * 2),
    fontSize: (0, _sizes.pixular)(_sizes.pixularFontSizeConstants.md),
    iconMargin: (0, _sizes.pixular)(_sizes.genericSizeConstants.xs)
  },
  lg: {
    horizontalPadding: (0, _sizes.pixular)(_sizes.genericSizeConstants.xs * 5),
    verticalPadding: (0, _sizes.pixular)(_sizes.genericSizeConstants.xs * 2),
    fontSize: (0, _sizes.pixular)(_sizes.pixularFontSizeConstants.lg),
    iconMargin: (0, _sizes.pixular)(_sizes.genericSizeConstants.sm)
  },
  xlg: {
    horizontalPadding: (0, _sizes.pixular)(_sizes.genericSizeConstants.xs * 6),
    verticalPadding: (0, _sizes.pixular)(_sizes.genericSizeConstants.xs * 2),
    fontSize: (0, _sizes.pixular)(_sizes.pixularFontSizeConstants.xlg),
    iconMargin: (0, _sizes.pixular)(_sizes.genericSizeConstants.sm)
  }
};
var _default = sizes;
exports["default"] = _default;