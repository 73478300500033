import React from "react";
import { Block, Text } from "@coastomizer/essentials";
import colorConstants from "../constants/colors";

class TalentItem extends React.Component {
  render() {
    return (
      <Block
        customStyle={[
          {
            display: "flex",
            flexDirection: "column",
            marginBottom: "35px",
          },
          {
            selector: ":last-of-type",
            ruleset: [
              {
                marginBottom: "0",
              },
            ],
          },
        ]}
      >
        <Block marginBottom>
          <Text h5 color={colorConstants.secondary}>
            {this.props.title}
          </Text>
        </Block>
        <Block>
          <Text
            paragraph
            color={colorConstants.dark}
            customStyle={[{ lineHeight: "1.5em" }]}
          >
            {this.props.description}
          </Text>
        </Block>
      </Block>
    );
  }
}

export default TalentItem;
