import React from "react";
import { Block, Image, Text } from "@coastomizer/essentials";
import { Button } from "@coastomizer/buttons";
import TalentItem from "./Components/TalentItem.component";
import { buttonSecondaryTheme } from "./constants/themes";
import { buttonDefaultSize } from "./constants/sizes";
import colorConstants from "./constants/colors";
import PortfolioItem from "./Components/PortfolioItem.component";
import { devices } from "@coastomizer/devices";
import { downScreen } from "@coastomizer/selectors";

function App() {
  return (
    <Block>
      <Block
        customStyle={[
          {
            width: "1200px",
            height: "100%",
            margin: "0 auto",
            display: "flex",
            flexDirection: "row",
            paddingTop: "10vh",
            paddingBottom: "10vh",
          },
          {
            selector: downScreen(devices.md),
            ruleset: [
              {
                flexDirection: "column",
                paddingTop: "35px",
                paddingBottom: "35px",
                width: "100%",
              },
            ],
          },
          {
            selector: downScreen(devices.xlg),
            ruleset: [
              {
                width: "900px",
              },
            ],
          },
        ]}
      >
        <Block
          customStyle={[
            {
              display: "flex",
              flexDirection: "column",
              flexBasis: "60%",
              padding: "0 15px",
              textAlign: "left",
            },
            {
              selector: downScreen(devices.md),
              ruleset: [
                {
                  width: "100%",
                  marginBottom: "35px",
                },
              ],
            },
          ]}
        >
          <Block marginBottom="20px">
            <Image
              source={require("./assets/images/Avatar.png")}
              height="64px"
            />
          </Block>
          <Block marginBottom>
            <Text h4 color={colorConstants.secondary}>
              S. Ahmadreza H.M
            </Text>
          </Block>
          <Block marginBottom="20px">
            <Text
              paragraph
              color={colorConstants.dark}
              customStyle={[{ lineHeight: "1.5em" }]}
            >
              is a Full-stack developer. He started coding when was a teenager.
              Now in early twenties, he is striving to accomplish much more than
              he thought he could achieve.
            </Text>
          </Block>
          <Block>
            <Block
              as="a"
              href="mailto:aiispassw@gmail.com"
              customStyle={[
                {
                  textDecoration: "none",
                },
              ]}
            >
              <Button
                text="Contact Me"
                theme={buttonSecondaryTheme}
                size={buttonDefaultSize}
                borderRadius="20px"
              />
            </Block>
          </Block>
        </Block>
        <Block
          customStyle={[
            {
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              padding: "0 15px",
              borderLeft: "1px solid #F2EFE9",
              height: "80vh",
              overflowY: "auto",
            },
            {
              selector: "::-webkit-scrollbar",
              ruleset: [
                {
                  width: "10px",
                },
              ],
            },
            {
              selector: "::-webkit-scrollbar-track",
              ruleset: [
                {
                  backgroundColor: colorConstants.light,
                  borderRadius: "30px",
                },
              ],
            },
            {
              selector: "::-webkit-scrollbar-thumb",
              ruleset: [
                {
                  backgroundColor: colorConstants.secondary,
                  borderRadius: "30px",
                },
              ],
            },
            {
              selector: downScreen(devices.md),
              ruleset: [
                {
                  overflowY: "initial",
                },
              ],
            },
          ]}
        >
          <Block
            customStyle={[
              {
                display: "flex",
                flexDirection: "column",
                marginBottom: "40px",
              },
            ]}
          >
            <Block marginBottom>
              <Text h5 color={colorConstants.primary}>
                Talents
              </Text>
            </Block>
            <TalentItem
              title="HTML, CSS, LESS, SASS, JQUERY, TailwindCSS"
              description="He can develop webpages using Hyper-text markup language, style
                them using CSS, make them interactive with JS and use goodies like
                Less, Sass, Jquery."
            />
            <TalentItem
              title="ReactJS, ReduxJS, React Router, NextJS, Antd"
              description="He can develop efficient SPAs using ReactJS alongside with using some crafted React NPM packages. He uses ReduxJS to build stateful components on a regular basis!"
            />
            <TalentItem
              title="VueJS, Vue Router, Pinia, NuxtJS, Quasar"
              description="He can develop descent UIs within VueJS ecosystem."
            />
            <TalentItem
              title="Javascript, Typescript, NodeJS"
              description="He can develop modern back-end intensive-IO-friendly services using this fabulous set of tools.
                He also, is able to create interfaces using REST and GraphQL proposals."
            />
            <TalentItem
              title="C/C++"
              description="He can develop general-purpose softwares using C/C++ programming languages. He is able to create interfaces backed by invented reliable robust set of handlers."
            />
            <TalentItem
              title="Java"
              description="He is able to use Java as a general-purpose programming language to develop different kinds of software solutions."
            />
            <TalentItem
              title="Software Design and Engineering"
              description="Proficiency in developing software design, making architectural decisions and provisioning well-written documentations including use cases, class hierarchy diagrams, state machine diagrams, activity diagrams, sequence diagrams, data model diagrams. (e.g. EERD, etc)"
            />
            <TalentItem
              title="Quality Assurance"
              description="He is familiar with writing test cases in environments like NodeJS and C/C++ ecosystem. Also, he is able to create integration-tests to test APIs and interfaces."
            />
            <TalentItem
              title="DevOps (Docker, Docker Swarm, Nomad, Terraform, etc)"
              description="He is familiar with tools like Docker, Kubernates, and also, CI/CD scoped utilities as Git, Drone, etc. He is able to automate critical parts of daily workloads."
            />
            <TalentItem
              title="UI/UX"
              description="He can develop expressive high-quality UI/UX designs and assure the best results from designed prototypes."
            />
            <TalentItem
              title="Database"
              description="Knowledgable about MongoDB (NoSQL) and MySQL (SQL) database systems."
            />
            <TalentItem
              title="BaaS (Parse, etc)"
              description="Experience in using Backend-as-a-service systems to manage back-end side of applications."
            />
            <TalentItem
              title="Algorithm Design and Data structure"
              description="He has some experience in solving problems using algorithms. He loves to learn more about them in practical work job."
            />
            <TalentItem
              title="Data Mining & Web Scraping"
              description="He is able to create data mining tools and use them to extract data from websites (and applications) to be used in data processing levels."
            />
            <TalentItem
              title="Linux (& trivially, Windows)"
              description="Proficiency in using Linux environments to handle routines and administrate servers."
            />
            <TalentItem
              title="Wordpress"
              description="He is proficient in using Wordpress as a CMS to manage different kinds of websites from blogs to enterprise-level retails."
            />
            <TalentItem
              title="Content Management"
              description="He is able to manage and producte efficient content to be used within enterprise applications and websites."
            />
            <TalentItem
              title="Embedded System Software Development"
              description="Minor experience in developing embedded system software."
            />
          </Block>
          <Block
            customStyle={[
              {
                display: "flex",
                flexDirection: "column",
                marginBottom: "60px",
              },
            ]}
          >
            <Block marginBottom>
              <Text h5 color={colorConstants.primary}>
                Portfolio
              </Text>
            </Block>
            <PortfolioItem
              title="Bregstar Social Media (UI/UX design & Backend implementation)"
              description="A social media with all essential features."
              link="https://mega.nz/file/CX4nybDK#1JtbF5ILlKEv9rKt6ty9-Tb_lVEGzW5gbuJBvFEjDPU"
              symbolColor="#5287ff"
              symbolText="BS"
              symbolTextColor="#FFFFFF"
            />
            <PortfolioItem
              title="MyPureGem (UI/UX design)"
              description="My Pure Gem is intended to be a retail selling jewelry products globally. We used artistic methods to design the work and influence potential buyers."
              link="https://mega.nz/file/6Sp1mbyJ#NMOWAAKwctiwDsMaa3QPUqRtuXpRtyHmI3Xlhadgxko"
              symbolColor="#e452ff"
              symbolText="PG"
              symbolTextColor="#FFFFFF"
            />
            <PortfolioItem
              title="DIAS"
              description="A fully-fledged system to develop state-intensive batch processes; manage and monitor them."
              link="https://mega.nz/file/mXhSgYCK#gY3An_raGDtYKxzLNNVoI31TRzlR4UlWVX0xwQXP3BY"
              symbolColor="#ff6a52"
              symbolText="DS"
              symbolTextColor="#FFFFFF"
            />
            <PortfolioItem
              title="Coastomizer ReactJS-based UI Kit"
              description="A complete set of essential UI elements for ReactJS environment."
              link="https://mega.nz/file/aDx3QbwA#_8o2Rom0YRNepDOWEOfY8XlWsTKrq-O1m9nD6jYVxfU"
              symbolColor="#9244a1"
              symbolText="CO"
              symbolTextColor="#FFFFFF"
            />
            <PortfolioItem
              title="Offcode"
              description="A framework based on JS and powered by ExpressJS to make it easier to develop web-servers by providing different kinds of interfaces."
              link="https://mega.nz/file/HCYTzQoC#ImQZG84XLhI6x4MSINSfZadN-qqJli_fTN_o6i1jM1E"
              symbolColor="#397EE5"
              symbolText="OF"
              symbolTextColor="#FFFFFF"
            />
            <PortfolioItem
              title="Behsaman Group Official Website"
              description="Official site of Behsaman Energy Group at behsamangroup.com."
              link="https://mega.nz/file/aSxUUQAT#Iz_KMt4vPUT2nkBdP_UtC18QvzeWrZNq1ScpRonqBNs"
              symbolColor="#84DA66"
              symbolText="BS"
              symbolTextColor="#FFFFFF"
            />
            <PortfolioItem
              title="Adminito"
              description="GUI web based admin panel designed to help developers to focus on underlying logic of their software instead of being concerned of such things."
              link="https://mega.nz/file/ODAgnaxK#aMvjGizyQelpZILKp1121ywBqXo7LJaxXBaiX6Aj7Ls"
              symbolColor="#707070"
              symbolText="AD"
              symbolTextColor="#FFFFFF"
            />
            <PortfolioItem
              title="Famenet"
              description="A social network which is intended to be used by people around the globe. It features general aspects of a social network, plus tons of other goodies."
              link="https://mega.nz/file/THRykYRK#tRrreB1Nl-POh3vznkQLNxXWv0WxqW2tsDEP8yJrpSg"
              symbolColor="#A64EB5"
              symbolText="FN"
              symbolTextColor="#FFFFFF"
            />
            <PortfolioItem
              title="Khatbarg"
              description="Is a platform to be used to create online exams in two common modes, contest and free-time exams. It allows users to follow each other and make their own exams out of thought."
              link="https://mega.nz/file/TCRyCKzR#k7J796BBP_0KvOm2WV1mEbKk7w4dNKhBRhQqQjueheU"
              symbolColor="#2DB574"
              symbolText="KH"
              symbolTextColor="#FFFFFF"
            />
            <PortfolioItem
              title="OtomatJS"
              description="A framework based on JS and powered by Puppeteer engine to create automated frames of work on web pages."
              link="https://mega.nz/file/mSYnSI4R#2XVMJmlc4HNdOp6nP_wqIkgzIIhZgXpL_7QqJfXgFHM"
              symbolColor="#B53D2D"
              symbolText="OT"
              symbolTextColor="#FFFFFF"
            />
            <PortfolioItem
              title="CervicerJS"
              description="Library to create services based-on ordered middlerare run processes. It's an agnostic extensive ExpressJS. It uses, middlewares and mixins to accomplish its job."
              link="https://mega.nz/file/yCwWyAAR#jYgKn7F5dUOltX-NaTyhsTG3lIHpAQzlccEK0LYQ_nw"
              symbolColor="#b5982d"
              symbolText="CS"
              symbolTextColor="#FFFFFF"
            />
            <PortfolioItem
              title="libxcmd"
              description="Is a C/C++ library providing an easy-to-use interface for programmers to create their own CLI based softwares. It uses commands, registries and handlers to achieve the feat."
              link="https://mega.nz/file/Gaxm3ZhA#VDYkdCrAxr-XaiPEQui1FAj1vqIQj4v5zxpjlh4A32I"
              symbolColor="#2dafb5"
              symbolText="CM"
              symbolTextColor="#FFFFFF"
            />
            <PortfolioItem
              title="libxdata"
              description="Major data structure implementation in C programming language."
              link="https://mega.nz/file/6TITDR7b#a-FB9qikjbaSmP2xpnZV0zPffEPBpq-xdTqBEy0QfpY"
              symbolColor="#3db93b"
              symbolText="DT"
              symbolTextColor="#FFFFFF"
            />
          </Block>
        </Block>
      </Block>
    </Block>
  );
}

export default App;
