import React from "react";
import { Block, Text } from "@coastomizer/essentials";
import colorConstants from "../constants/colors";

class PortfolioItem extends React.Component {
  render() {
    return (
      <Block
        customStyle={[
          {
            display: "flex",
            flexDirection: "column",
            marginBottom: "35px",
          },
          {
            selector: ":last-of-type",
            ruleset: [
              {
                marginBottom: "0",
              },
            ],
          },
        ]}
      >
        <Block
          marginBottom
          customStyle={[
            { display: "flex", flexDirection: "row", alignItems: "center" },
          ]}
        >
          <Block
            customStyle={[
              {
                height: "32px",
                width: "32px",
                borderRadius: "50%",
                marginRight: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: this.props.symbolColor,
              },
            ]}
          >
            <Text size="10px" color={this.props.symbolTextColor} bold>
              {this.props.symbolText}
            </Text>
          </Block>
          <Text h5 color={colorConstants.secondary}>
            {this.props.title}
          </Text>
        </Block>
        <Block marginBottom>
          <Text
            paragraph
            color={colorConstants.dark}
            customStyle={[{ lineHeight: "1.5em" }]}
          >
            {this.props.description}
          </Text>
        </Block>
        <Block>
          <Block
            as="a"
            role="a"
            href={this.props.link}
            customStyle={[{ textDecoration: "none" }]}
            target="_blank"
          >
            <Text color={colorConstants.link}>View Project</Text>
          </Block>
        </Block>
      </Block>
    );
  }
}

export default PortfolioItem;
