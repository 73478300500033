const colorConstants = {
  primary: "#D9CAB3",
  light: "#F2EFE9",
  dark: "#565254",
  darker: "#333333",
  secondary: "#C97C5D",
  lighter: "#FFFFFF",
  link: "#5181FD",
};

export default colorConstants;
