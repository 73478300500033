"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _colors = require("@coastomizer/colors");
var themes = {
  light: {
    passiveBackgroundColor: _colors.colors.lightWhite,
    passiveBorderColor: _colors.colors.complementWhite,
    passiveFontColor: _colors.colors.darkBlack,
    activeBackgroundColor: _colors.colors.darkWhite,
    activeBorderColor: _colors.colors.complementWhite,
    activeFontColor: _colors.colors.darkBlack,
    disabledBackgroundColor: _colors.colors.darkWhite,
    disabledBorderColor: _colors.colors.complementWhite,
    disabledFontColor: _colors.colors.highWhite
  },
  dark: {
    passiveBackgroundColor: _colors.colors.lightBlack,
    passiveBorderColor: _colors.colors.complementBlack,
    passiveFontColor: _colors.colors.lightWhite,
    activeBackgroundColor: _colors.colors.darkBlack,
    activeBorderColor: _colors.colors.complementBlack,
    activeFontColor: _colors.colors.lightWhite,
    disabledBackgroundColor: _colors.colors.lowBlack,
    disabledBorderColor: _colors.colors.lowBlack,
    disabledFontColor: _colors.colors.lightBlack
  }
};
var _default = themes;
exports["default"] = _default;