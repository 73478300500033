"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var colors = {
  lightWhite: "#ffffff",
  darkWhite: "#f3f3f3",
  complementWhite: "#cecece",
  highWhite: "#afafaf",
  lightBlack: "#333333",
  darkBlack: "#1b1b1b",
  complementBlack: "#4c4c4c",
  lowBlack: "#757575",
  warning: "#e6dd6d",
  danger: "#bf3648",
  success: "#2ca22b",
  info: "#3a8ed6"
};
var _default = colors;
exports["default"] = _default;