import colorConstants from "./colors";

const buttonSecondaryTheme = {
  activeBackgroundColor: colorConstants.secondary,
  passiveBackgroundColor: colorConstants.secondary,
  passiveFontColor: colorConstants.lighter,
  activeFontColor: colorConstants.lighter,
  activeBorderColor: colorConstants.secondary,
  passiveBorderColor: colorConstants.secondary,
};

export { buttonSecondaryTheme };
